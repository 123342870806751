import { IconArrowRight } from "@tabler/icons-react";
import React from "react";

export function ConsultationFooter() {
  return (
    <div className="sticky -inset-x-2 bottom-0 z-10 -mx-2 border-t border-neutral-300 bg-neutral-100 p-4 text-sm text-neutral-900">
      Not sure what scan you need?{" "}
      <a
        href="/services/consultation"
        className="inline-flex items-center gap-x-1.5 font-medium underline hover:no-underline"
      >
        Book a consultation <IconArrowRight />{" "}
      </a>
    </div>
  );
}
