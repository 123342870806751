import classNames from "classnames";

export const labelStyles = "block text-sm text-neutral-500 flex gap-x-1";
export const optionsStyles =
  "ring-primary-300 absolute left-0 mt-2 space-y-px overflow-auto rounded-lg border border-neutral-200 bg-white p-2 text-sm font-medium text-neutral-700 shadow-lg focus:outline-none focus-visible:ring-2 min-w-full z-20";

export function itemStyles(
  item: { selected: boolean; active: boolean },
  className?: string | boolean
) {
  return classNames(
    item.active && "bg-neutral-100",
    "cursor-pointer rounded-md p-2.5 flex items-center gap-2 select-none",
    className
  );
}

export function inputWrapperStyles(error = false, isGrouped = false) {
  return classNames(
    "h-fit",
    error && "space-y-2",
    error && isGrouped && "lg:space-y-0"
  );
}

export function targetStyles(
  isGrouped?: boolean,
  ...styles: classNames.ArgumentArray
) {
  return classNames(
    "flex items-center gap-x-2.5 text-start gap-x-2.5 px-3  w-full",
    "text-base bg-white font-medium",
    "text-neutral-950 ",
    "focus:outline-none focus-visible:ring-2 ring-primary-300",
    "border border-neutral-300 rounded-lg min-h-[63px]",
    isGrouped && "lg:border-0 lg:min-h-[44px] lg:py-0 lg:px-[18px]",
    ...styles
  );
}
