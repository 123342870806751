import { IconAlertCircle } from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";

type Comp = Omit<React.ComponentProps<"div">, "children">;

interface SystemMessageProps extends Comp {
  message: string;
  type?: keyof (typeof MessageStyles)["variants"];
}

const MessageStyles = {
  base: "flex items-center gap-x-2 p-4 text-base font-medium border rounded-lg w-full",
  variants: {
    error: "bg-red-50 text-red-600 border-red-600",
    warning: "bg-warning-50 text-neutral-950 border-warning-400",
  },
} as const;

export function SystemMessage({
  message,
  className,
  type,
  ...props
}: SystemMessageProps) {
  const styles = classNames(
    MessageStyles["base"],
    type && MessageStyles["variants"][type],
    className
  );

  return (
    <div {...props} className={styles}>
      <IconAlertCircle className="h-6 w-6 shrink-0" />
      <p>{message}</p>
    </div>
  );
}
