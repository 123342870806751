import axios from "axios";

const token = document.querySelector<HTMLMetaElement>(
  'meta[name="csrf-token"]'
);
const RestClient = axios.create({
  headers: {
    "X-CSRF-Token": token?.content ?? "no-csrf-token",
    "Content-Type": "application/json",
  },
});

export default RestClient;
