import { useEffect, useState } from "react";
import type { BodyPart } from "../types";
import RestClient from "../../lib/RestClient";

interface UseBodyPartsProps {
  initialBodyParts?: BodyPart[];
  selectedScanTypeId: string;
  scanningLocationId?: string;
  onFetchSuccess?: (bodyParts: BodyPart[]) => void;
}

export function useBodyParts({
  initialBodyParts = [],
  scanningLocationId,
  selectedScanTypeId,
  onFetchSuccess,
}: UseBodyPartsProps) {
  const [bodyParts, setBodyParts] = useState(initialBodyParts);
  const [fetchedScanTypeId, setFetchedScanTypeId] =
    useState(selectedScanTypeId);

  async function fetchBodyParts() {
    const url = new URL(
      `/api/v1/scan_types/${selectedScanTypeId}/body_parts`,
      window.location.origin
    );

    if (scanningLocationId) {
      url.searchParams.append("location_id", scanningLocationId);
    }

    const response = await RestClient.get(url.toString());
    const data = response.data;
    const newBodyParts = data.body_parts || [];
    if (onFetchSuccess) {
      onFetchSuccess(newBodyParts);
    }
    return newBodyParts;
  }

  useEffect(() => {
    if (selectedScanTypeId === fetchedScanTypeId && bodyParts.length > 0) {
      return;
    }

    fetchBodyParts()
      .then(setBodyParts)
      .then(() => setFetchedScanTypeId(selectedScanTypeId));
  }, [selectedScanTypeId, scanningLocationId, fetchedScanTypeId]);

  return { bodyParts };
}
